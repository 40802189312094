import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@radix-ui/themes/styles.css';
import './index.css';
import { Theme, ThemePanel } from '@radix-ui/themes';
import { ThemeProvider } from 'next-themes';
import { Amplify } from 'aws-amplify';

Amplify.configure({
    // OPTIONAL - if your API requires authentication
    Auth: {
        Cognito: {
            region: 'us-east-1', // REQUIRED - Amazon Cognito Region
            userPoolId: 'us-east-1_CAkOVpZlh', // OPTIONAL - Amazon Cognito User Pool ID
            userPoolClientId: '5t2e32ubj1idn7hhkc90j0710e', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            loginWith: { // Optional
                oauth: {
                    domain: 'https://chat-ai.auth.us-east-1.amazoncognito.com',
                    scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: process.env.REACT_APP_D === 'true' ? 'http://localhost:3000/' : 'https://srt-ai.logcg.com/',
                    redirectSignOut: process.env.REACT_APP_D === 'true' ? 'http://localhost:3000/' : 'https://srt-ai.logcg.com/',
                    responseType: 'code',
                }
            }
        },
    },
    API: {
        endpoint: 'https://idp.api.logcg.com',
        region: 'us-east-1',
    }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider attribute="class">
            <Theme accentColor="blue" grayColor="sand">
                <App/>
                {/*<ThemePanel/>*/}
            </Theme>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
