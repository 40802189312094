import { Select } from '@radix-ui/themes';
import { useState } from 'react';

export const LanguageSelectorComponent = ({ onLanguageChange, defaultCode }) => {
    const languages = [
        { code: 'en', name: 'English' },
        { code: 'es', name: 'Spanish' },
        { code: 'fr', name: 'French' },
        { code: 'zh', name: 'Chinese' },
        { code: 'ja', name: 'Japanese' },
        { code: 'ko', name: 'Korean' },
        { code: 'de', name: 'German' },

        // Add more languages as needed
    ];

    const [selectedLanguage, setSelectedLanguage] = useState(defaultCode);

    const handleLanguageChange = (languageCode) => {
        const language = languages.find(lang => lang.code === languageCode);
        setSelectedLanguage(languageCode);
        onLanguageChange(language); // Propagate change to parent
    };



    return (
        <Select.Root onValueChange={handleLanguageChange} defaultValue={selectedLanguage}>
            <Select.Trigger/>
            <Select.Content>
                <Select.Group>
                    <Select.Label>Select a language</Select.Label>
                    {languages.map(language =>
                        <Select.Item key={language.code} value={language.code}>
                            {language.name}
                        </Select.Item>
                    )}
                </Select.Group>
            </Select.Content>
        </Select.Root>
    );
};
