import * as Progress from '@radix-ui/react-progress';


export const ProgressComponent = ({ progress }) => {
    // Style your progress container and filler as needed


    return (
        <Progress.Root className="ProgressRoot" value={progress}>
            <Progress.Indicator
                className="ProgressIndicator"
                style={{ transform: `translateX(-${100 - progress}%)` }}
            />
        </Progress.Root>
    );
};
