import MPEGMode from 'lamejs/src/js/MPEGMode';
import Lame from 'lamejs/src/js/Lame';
import BitStream from 'lamejs/src/js/BitStream';
import lamejs from 'lamejs';

window.MPEGMode = MPEGMode;
window.Lame = Lame;
window.BitStream = BitStream;



async function readFileAsBytes(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // This event handler will be called once the read operation is complete
        reader.onloadend = function() {
            // The result attribute contains the data as a data URL
            if (reader.readyState === FileReader.DONE) {
                const arrayBuffer = reader.result;

                // Resolve the promise with the byte array
                resolve(arrayBuffer);
            }
        };

        // Read the file as an ArrayBuffer
        reader.readAsArrayBuffer(file);
    });
}

/**
 * Merges the audio channels of an audio buffer into a single channel.
 *
 * @param {AudioBuffer} audioBuffer - The audio buffer to merge.
 * @return {AudioBuffer} - The merged audio buffer with a single channel.
 */
function mergeToMono(audioBuffer, audioContext) {
    // Create a single-channel buffer with the same length and sample rate as the original
    const monoBuffer = audioContext.createBuffer(1, audioBuffer.length, audioBuffer.sampleRate);

    // Use the first channel to accumulate the mixed samples
    const output = monoBuffer.getChannelData(0);

    // Iterate over each channel of the audioBuffer
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
        const inputChannelData = audioBuffer.getChannelData(channel);
        // Mix the channel into the output
        for (let i = 0; i < inputChannelData.length; i++) {
            output[i] += inputChannelData[i] / audioBuffer.numberOfChannels;
        }
    }

    return monoBuffer;
}


/**
 * Converts an audio buffer to an MP3 file.
 *
 * @param {AudioBuffer} audioBuffer - The audio buffer to convert.
 * @return {Blob} The converted MP3 file as a Blob object.
 */
function audioToMP3(audioBuffer) {
    // Initialize the MP3 encoder
    const mp3Encoder = new lamejs.Mp3Encoder(1, audioBuffer.sampleRate, 128);
    const samples = audioBuffer.getChannelData(0);
    const mp3Data = [];
    let blockSize = 1152; // number of samples per block for encoding
    //make the sound louder
    for(let i=0;i<samples.length;i++) {
        samples[i] = samples[i]*32767.5;
    }
    for (let i = 0; i < samples.length; i += blockSize) {
        let block = samples.subarray(i, i + blockSize);
        let mp3buf = mp3Encoder.encodeBuffer(block);
        if (mp3buf.length > 0) {
            mp3Data.push(new Int8Array(mp3buf));
        }
    }
    // Finish the MP3 encoding
    let mp3buf = mp3Encoder.flush();
    if(mp3buf.length > 0){
        mp3Data.push(new Int8Array(mp3buf));
    }
    // Create a Blob from the encoded MP3 data
    return new Blob(mp3Data, {type: 'audio/mp3'});
}

/**
 * Extracts the audio from a video file.
 *
 * @param {string} videoFile - The path or URL of the video file.
 * @return {Promise<Blob>} A promise that resolves with the decoded audio data as an AudioBuffer.
 */
export async function extractAudio(videoFile) {
    const audioContext = new(window.AudioContext || window.webkitAudioContext)();
    const audioBuffer =  await readFileAsBytes(videoFile).then((bytes) => audioContext.decodeAudioData(bytes))
    const mono = mergeToMono(audioBuffer, audioContext);
    // console.log('Audio Buffer:', audioBuffer);
    // console.log('Mono Buffer:', mono);
    return audioToMP3(mono);
    // mySoundBuffer = decodedAudioData;
    // var soundSource = audioContext.createBufferSource();
    // soundSource.buffer = mono;
    // soundSource.connect(audioContext.destination);
    // soundSource.start();
}
