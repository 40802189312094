import GPT3Tokenizer from 'gpt3-tokenizer';
import { getServerToken } from './server-api';
import { genHistoryForOpenAI, getServerAPI } from '../utils';
import { chatAIModels } from './price-models';

const host = 'https://api.chatai.beauty'; // process.env.REACT_APP_D === 'true' ? 'http://127.0.0.1:5000' : ;
let chatAIAskEndpoint = `${host}/v1/chat-ai`;

if (process.env.REACT_APP_D !== 'true') {
    getServerAPI(host).then((serverAPI) => {
        if (serverAPI) {
            chatAIAskEndpoint = serverAPI + '/v1/chat-ai';
        }
    });
}

const tokenizer = new GPT3Tokenizer({ type: 'gpt3' });

async function callbackProcessStream(res, model, baseToken, callback) {
    let tokenCount = 0;
    const decoder = new TextDecoder('utf-8');
    const reader = res.body.getReader();
    let done, value;
    while (!done) {
        try {
            ({ value, done } = await reader.read());
        } catch (e) {
            console.log(e);
            if (e.name === 'AbortError') {
                let cost = (baseToken / model.promptTokenPerDollar) + (tokenCount / model.completionTokenPerDollar);
                callback && callback(null, cost, null, { canContinue: true });
            } else {
                callback && callback(null, null, e);
            }

            break;
        }

        const dataString = decoder.decode(value);
        const lines = dataString.split('\n').filter(line => line.startsWith('data: '));
        for (const line of lines) {
            let d = line.slice(6);
            if (d === '[DONE]') continue;
            tokenCount++;
            try {
                let d_obj = JSON.parse(d);
                if (d_obj.choices[0].finish_reason !== null && d_obj.choices[0].finish_reason !== 'start') {
                    let promptTokens = baseToken;
                    let completionTokens = tokenCount;
                    const fact = d_obj.fact_check_content || null;
                    const webContents = d_obj.web_contents || null;
                    const canContinue = d_obj.choices[0].finish_reason !== 'stop';
                    if (d_obj.prompt_tokens) {
                        promptTokens = d_obj.prompt_tokens;
                    }
                    if (d_obj.completion_tokens) {
                        completionTokens = d_obj.completion_tokens;
                    }
                    let cost = (promptTokens / model.promptTokenPerDollar) + (completionTokens / model.completionTokenPerDollar);
                    callback && callback(null, cost, null, { fact, webContents, canContinue });
                    break;
                } else {
                    if (d_obj.choices[0].delta.content !== null
                        && d_obj.choices[0].delta.content !== ''
                        && d_obj.choices[0].delta.content !== undefined) {
                        callback && callback(d_obj.choices[0].delta.content, null);
                    }
                }
            } catch (e) {
                console.log(d);
                console.log(e);
            }
        }
    }
}

/**
 *
 * @param m
 * @param {[object]} history
 * @param callback
 * @param signal
 */
function askChatAI(m, history, callback) {
    const model = chatAIModels[m];
    const histories = genHistoryForOpenAI(history);

    const data = {
        chat_history: histories,
        model: m,
    };
    getServerToken()
        .then((accessToken) => fetch(chatAIAskEndpoint, {
            timeout: 30000,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(data)
        }))
        .then(res => {
            if (res.ok) {
                callbackProcessStream(res, model, 0, callback);
            } else {
                throw new Error(res.status);
            }
        })
        .catch(e => {
            if (e.name === 'AbortError') {
                // abort error could happens here when user abort the fetch before it starts streaming
                callback && callback(null, null, null, null);
            } else {
                callback && callback(null, null, e, null);
            }
        });

}

function whisperTranscript(accessToken, audioBlob) {
    const apiUrl = host + '/v1/audio/transcriptions';
    // Create a new FormData object
    let formData = new FormData();
    // Append the mp3Blob to the form data, the file name will be 'audio.mp3'
    formData.append('file', audioBlob, 'audio.mp3');
    // Append the model field
    formData.append('model', 'whisper-1');
    formData.append('response_format', 'srt');
    // Use fetch to send the form data
    return fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            // Don't set Content-Type here, the browser will set it with the correct boundary for multipart/form-data
        },
        body: formData
    }).then(response => response.json()).then(result=>result.msg) // Assuming the server responds with SRT
}

export { askChatAI, whisperTranscript };

