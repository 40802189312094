import { Button, Text, Flex, Section } from '@radix-ui/themes';
import { Dialog, TextField} from '@radix-ui/themes';
import { useState } from 'react';
import { signIn } from 'aws-amplify/auth';
import { RotatingLines } from 'react-loader-spinner';

export const LoginComponent = ({ authUser }) => {
    const [isOpen, setIsOpen] = useState(authUser == null); // Using this state to control the dialog visibility
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const t = (key) => key;
    const handleLogin = async (e) => {
        e.preventDefault();
        // Here we would place our login logic
        try {
            setLoading(true);
            await signIn({ username, password });
            setError(t('登录成功！'));
            // refresh page
            window.location.reload();
        } catch (error) {
            setError(error.message);
            if (error.code === 'UserNotConfirmedException') {
                setError(t('请先验证邮箱！'));
                // redirect to verify email page
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
            <Dialog.Trigger asChild>
                <Button>Login</Button>
            </Dialog.Trigger>
            <Dialog.Content>
                <Dialog.Title>Please use Chat-AI account sign-in</Dialog.Title>
                <Dialog.Description>
                    Srt-AI will consume Chat-AI points to do the translation.
                </Dialog.Description>
                <form onSubmit={handleLogin}>
                    <Flex direction="column" gap="2" align="stretch" pt="6">
                        <TextField.Root id="username" required onChange={e => setUsername(e.target.value)}>
                            <TextField.Slot>
                                <Text htmlFor="username">Username</Text>
                            </TextField.Slot>
                        </TextField.Root>
                        <TextField.Root id="password" type="password" required onChange={e => setPassword(e.target.value)}>
                            <TextField.Slot>
                                <Text htmlFor="password">Password</Text>
                            </TextField.Slot>
                        </TextField.Root>
                        <Section size="1">
                            <Flex justify="between">
                                {<Text color="pink">{error}</Text>}
                                <Flex justify="end" gap="3">
                                    <Dialog.Close asChild>
                                        <Button>Close</Button>
                                    </Dialog.Close>
                                    <Button type="submit" disabled={loading}>{loading ?
                                        <RotatingLines
                                            strokeColor="#fff"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="20"
                                            wrapperClassName="mx-auto"
                                            visible={true}
                                        />
                                        :
                                        'Log in'}</Button>
                                </Flex>
                            </Flex>
                        </Section>
                    </Flex>
                </form>

            </Dialog.Content>
        </Dialog.Root>
    );
};

export default LoginComponent;
