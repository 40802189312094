import { fetchAuthSession } from 'aws-amplify/auth';
import {
    getServerAPI,
} from '../utils';
import { isExpired } from "react-jwt";

const host = 'https://api.chatai.beauty'//process.env.REACT_APP_D === 'true' ? 'http://127.0.0.1:5000' : 'https://api.chatai.beauty';
let chatAIEndpoint = host + '/v1';


if (process.env.REACT_APP_D !== 'true') {
    getServerAPI(host).then((serverAPI) => {
        if (serverAPI) {
            chatAIEndpoint = serverAPI + '/v1';
        }
    });
}


function getLatestVersionInfo() {
    return fetch(chatAIEndpoint + '/client/version', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => res.json());
}


/**
 *
 * @returns {Promise<String>}
 */
function getServerToken() {
    return new Promise((resolve, reject) => {
        fetchAuthSession().then((session) => {
            const accessToken = session.tokens.idToken.toString();
            if (isExpired(accessToken)) {
                fetchAuthSession({forceRefresh: true}).then((session) => {
                    const accessToken = session.tokens.idToken.toString();
                    resolve(accessToken);
                });
                // do whatever you want to do now :)
            } else {
                resolve(accessToken);
            }
        }).catch((err) => {
            reject(err);
        });
    });

}

/**
 * {
 *     "_updated_at": "2023-02-06T11:53:32.888638+0000",
 *     "credit": 0,
 *     "sub": "92df14c2-d58c-44eb-9efb-edcdd6361aa7"
 *     "tg_id": "123456789", // telegram id if exist
 *     "config": {} // if exist
 * }
 * @returns {Promise<any>}
 */
function getUserInfo() {
    return getServerToken()
        .then((accessToken)=>fetch(chatAIEndpoint + '/user', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    })).then(res => res.json());
}


function resetAccessToken() {
    return getServerToken()
        .then((accessToken)=>fetch(chatAIEndpoint + `/user/reset-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        })).then(res => res.json());
}



export {
    getServerToken,
    getUserInfo,
    resetAccessToken,
    getLatestVersionInfo,
};
