import GPT3Tokenizer from 'gpt3-tokenizer';
import { askChatAI } from './open-ai';

const tokenizer = new GPT3Tokenizer({ type: 'gpt3' });

/**
 * split list into nPart
 * @param nPart
 * @param list
 * @return {*[{}]}
 */
function listSplitTo(nPart, list) {
    let result = [];
    let partSize = Math.ceil(list.length / nPart);
    for (let i = 0; i < nPart; i++) {
        const tmp = {};
        const part = list.slice(i * partSize, (i + 1) * partSize);
        for (let j = 0; j < part.length; j++) {
            const index = i * partSize + j;
            tmp[index] = part[j];
        }
        result.push(tmp);
    }
    return result;
}

function srtSplitTo(nPart, srtString) {
    // Normalize line endings and split the input by empty lines to get individual subtitles
    const subtitles = srtString.replace(/\r\n/g, '\n').trim().split(/\n\n+/);

    // Calculate the approximate size of each part
    const partSize = Math.ceil(subtitles.length / nPart);
    const parts = [];

    for (let i = 0; i < subtitles.length; i += partSize) {
        // Slice the subtitles array to get a partSize chunk and join it back with double newlines
        parts.push(subtitles.slice(i, i + partSize).join('\n\n'));
    }

    return parts;
}

let resultBuffer = '';
function handleAskCallbackStream(text, cost, err, extras, resolve) {
    const result = {
        err: null,
        text: null,
        list: null,
    }
    if (err) {
        console.log(err);
    } else if (text) {
        resultBuffer += text;
    }

    if (text === null && cost) { // means stream finished
        try {
            const a = resultBuffer.split('```');
            result.text = a[1].substring(9);
        } catch (e) {
            result.err = e;
            result.text = resultBuffer;
        }
        resolve(result);
    }

}


function buildSrt(timestamps, subtitles) {
    let result = '';
    for (let i = 0; i < timestamps.length; i++) {
        result += (i + 1) + '\n';
        result += timestamps[i] + '\n';
        result += subtitles[i] + '\n';
        result += '\n';
    }
    return result;
}

/**
 *
 * @param contents
 * @param fromLang
 * @param targetLang
 * @param resultStore
 * @return {Promise<void>}
 */
function translate(contents, fromLang, targetLang, resultStore) {
    const prompt = `I have an SRT file with subtitles that need to be translated from ${fromLang} to ${targetLang}. The file contains timed text entries where each entry has a numerical identifier, a time range, and one or more lines of text. Please translate each line or set of lines associated with a single time range separately without combining them. Maintain the original line breaks and numbering so that each translation matches the original timing.Please translate each entry in the same order and format, keeping the numerical identifiers and time ranges intact. Thank you.\n\n`;
    const promiseList = [];
    for (let i = 0; i < contents.length; i++) {
        const content = contents[i];
        const finalPrompt = prompt + content;
        const p = new Promise((resolve, reject) => {
            askChatAI('gpt-4o', [
                {
                    sender: 'Human',
                    content: finalPrompt
                }
            ], (text, cost, err, extras) => handleAskCallbackStream(text, cost, err, extras, resolve));
        })
        promiseList.push(p);
    }

    return Promise.all(promiseList).then(results => {
        for (let i = 0; i < results.length; i++) {
            const result = results[i];
            if (result.err) {
                console.log(result);
            } else {
                resultStore.current[i] = result.text;
            }
        }
    })
}
/**
 * prepare for translate
 * @param {string} content
 * @return {string[]} the list of string block for translate
 */
function prepareForTranslate(content) {
    const encoded = tokenizer.encode(content);
    const baseToken = encoded.text.length;
    let parts = 1;
    if (baseToken > 4000) {
        parts = Math.ceil(baseToken / 4000);
    }
    return srtSplitTo(parts, content);
}


/**
 * split srt content into two objects, one for the time, one for the content
 * @param srt
 * @return {*}
 */
function parseSrt(srt) {
    // Split the SRT file into an array of lines
    const lines = srt.split('\n');

    // Initialize the lists for timestamps and subtitles
    const timestamps = [];
    const subtitles = [];

    // Iterate over the lines of the SRT file
    for (let i = 0; i < lines.length; i++) {
        // Get the current line and remove \n
        const line = lines[i];
        // Check if the current line is a timestamp line
        if (line.match(/^\d+:\d+:\d+,\d+ --> \d+:\d+:\d+,\d+$/)) {
            // Add the timestamp to the list of timestamps
            timestamps.push(line.replace(/\n/g, ''));
        }
        // Check if the current line is a subtitle line
        else if (line && !line.match(/^\s*$/) && !line.match(/^\d+$/)) {
            // Add the subtitle to the list of subtitles
            subtitles.push(line.replace(/\n/g, ''));
        }
    }

    // Return the lists of timestamps and subtitles
    return { timestamps, subtitles };
}


export { parseSrt, prepareForTranslate, translate, buildSrt };
