

/**
 * [{sender: 'Human', content: 'Hello', cost: 0.1, fact:''}]
 * @param history
 * @returns {[]}
 */
function genHistoryForOpenAI(history) {
    const histories = [];
    for (let i = 0; i < history.length; i++) {
        const item = history[i];
        if (item.sender === 'SYSTEM') {
            continue;
        }

        if (item.sender === 'AI') {
            if (item.fact) {
                histories.push({
                    role: 'system',
                    content: item.fact
                });
            }

            if (item.webContents && item.webContents.length > 0) {
                for (let j = 0; j < item.webContents.length; j++) {
                    histories.push({
                        role: 'system',
                        content: item.webContents[j]
                    });
                }
            }

            histories.push({
                role: 'assistant',
                content: item.content
            });
        } else {
            histories.push({
                role: 'user',
                content: item.content
            });
        }
    }
    return histories;
}

/**
 * Check if the API is reachable
 * @param {string} url
 * @returns {Promise<boolean>}
 */
async function isApiReachable(url) {
    try {
        const response = await fetch(url, { method: 'HEAD' }); // Using HEAD method for minimal data transfer
        return response.ok; // Will be true if status code is 200-299
    } catch (error) {
        console.error('API not reachable:', error);
        return false;
    }
}


async function getServerAPI(defaultAPI) {

    if (await isApiReachable(defaultAPI)) {
        console.log('default api is reachable 🎉');
        return defaultAPI;
    }

    try {
        const apiList =  await fetch('https://api.backup.chatai.beauty/v1/health')
            .then(res => res.json())
        for (const api of apiList.api) {
            const endpoint = 'https://' + api;
            if (endpoint === defaultAPI) {
                continue;
            }
            if (await isApiReachable(endpoint)) {
                return endpoint;
            }
        }
    } catch (e) {
        console.log(e);
    }
    return defaultAPI
}

export {
    getServerAPI,
    genHistoryForOpenAI
}
