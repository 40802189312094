import { Text, Button, Flex } from '@radix-ui/themes';
import { useEffect, useState } from 'react';

export const DownloadLinksComponent = ({ englishSrtTxt, translatedSrtTxt, originalLanguage, translationLanguage }) => {

    const [hide, setHide] = useState(true);

    useEffect(() => {
        if (englishSrtTxt && translatedSrtTxt) {
            setHide(false);
        } else {
            setHide(true);
        }
    }, [englishSrtTxt, translatedSrtTxt]);

    // Check if the URLs are provided before rendering
    function downloadOriginalSrt(e) {
        e.preventDefault();
        const element = document.createElement('a');
        const file = new Blob([englishSrtTxt], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = originalLanguage.toLowerCase() + '.srt';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        // remove the element when done
        document.body.removeChild(element);
    }

    function downloadTranslatedSrt(e) {
        e.preventDefault();
        const element = document.createElement('a');
        const file = new Blob([translatedSrtTxt], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = translationLanguage.toLowerCase() + '.srt';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        // remove the element when done
        document.body.removeChild(element);
    }

    return (!hide &&
        <Flex direction="column" align="center" gap="3">
            <Text as="h2" size="3">Download SRT files</Text>
            <Flex justify="center" gap="2">
                <Button as="a" onClick={(e) => downloadOriginalSrt(e)} download>
                    Download {originalLanguage} Transcript (SRT)
                </Button>
                <Button as="a" onClick={e => downloadTranslatedSrt(e)} download>
                    {`Download ${translationLanguage} Transcript (SRT)`}
                </Button>
            </Flex>
        </Flex>
    );
};
