import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Flex } from '@radix-ui/themes';
// Include any additional Radix UI components you may need

const fileTypes = ['MP4', 'MP3', 'WAV', 'MOV', 'FLAC']; // Add or remove file types as needed

export const FileUploaderComponent = ({ onFileUpload }) => {
    const [file, setFile] = useState(null);

    const handleChange = (file) => {
        setFile(file);
        onFileUpload(file); // Prop function to handle the uploaded file
    };

    return (
        <Flex direction="row" justify="center" gap="3" grow='1'>
            <FileUploader classes={'UploaderStyle'} label={'Drag and drop your video here or click to select file to start'}
                          handleChange={handleChange} name="file" types={fileTypes} accepts={fileTypes}/>
        </Flex>
    );
};
